
.sign-up{
    width: 100%;
    height: 100vh;
}

.sign-up .row{
    height: 100%;
}

.sign_up_img{
    width: 100%;
    height: 100%;
}

.sign-up .col-4 h2{
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #FCFCFC;
}

.sign-up .col-4{

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sign-up .form-box{
    width: 80%;
}

.sign-up input {
    color: #FCFCFC;
}

.sign-up .back-home{
    position: absolute;
    bottom: 20px;

    color: #FCFCFC;
    font-size: 16px;
    font-weight: 500;
}

.sign-up .back-home:hover{
    text-decoration: underline;
}


.sign-up .back-home i {
    margin-right: 20px;
}

.sign-up .goto-sign-in{
    color: #FCFCFC;
    font-size: 15px;
    font-weight: 500;
   
    position: relative;
    top: 5px;
    text-align: center;
}

.sign-up .goto-sign-in:hover{
    text-decoration: underline;
}

@media screen and (max-width: 600px){
    .sign-up{
        width: 100%;
        height: auto;
    }

    .sign_up_img{
        display: none;
    }

    .sign-up .back-home{
        position: absolute;
        bottom: -50px;
    
        font-size: 15px;
        font-weight: 400;
    }

    .sign-up .goto-sign-in{
        color: #FCFCFC;
        font-size: 14px;
        font-weight: 400;
       
        position: relative;
        top: 5px;
    }
    
}